import { Alert, Pane } from "evergreen-ui"
import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

export const AnnouncementBarWrap: FC = ({ children }) => {
    const location = useLocation();
    const [showAlertBanner, setShowAlertBanner] = useState<boolean>(true);
    const fullScreenRegex = [
        "/login", "/registration", "/logout", "/products/new", "/products/.*/catalog/edit", "/new-user/.*"
    ].join("|");

    return (
        <Pane className={"jc-app-container"}>
            <Pane
                display={(showAlertBanner && !location.pathname.match(fullScreenRegex)) ? "block" : "none"}
            >
                <Alert
                    id="announcement-banner"
                    borderRadius={0}
                    padding={18}
                    intent={"info"}
                    hasIcon={true}
                    title={"Check out the new Notification Settings!"}
                    isRemoveable
                    onRemove={() => {
                        setShowAlertBanner(false);
                    }}
                >
                    <p style={{ margin: "6px 0 0" }}>
                        By default, you’ll now receive Email & SMS notifications whenever you are tagged in a message. To change your account notification settings,
                        go to <b><a href="https://app.junipercreates.com/settings/notifications">Settings → Notifications</a></b>!
                    </p>
                </Alert>
            </Pane>
            <Pane
                className="jc-content-container"
                display="flex"
            >
                {children}
            </Pane>
        </Pane>
    );
};
