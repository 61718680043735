/**
 * To be used with `useEffect` in cases where the common styles on "jc-content-container" need to be altered.
 *  Mainly used in Messages.tsx and NativeMessages.tsx
 *
 * Also handles resetting styles on component unload
 */
export const removeJcContentContainerStylesEffect = (overflowClip: boolean = true) => {
    const topLevelDiv: HTMLElement | null = document.getElementById("jc-content-body");
    if (!topLevelDiv) {
        console.debug("Either jc-content-container div was removed or css id was renamed");
        return;
    }
    const oldPadding = topLevelDiv.style.padding;
    const oldOverflow = topLevelDiv.style.overflow;
    topLevelDiv.style.padding = "0px";
    if (overflowClip) {
        topLevelDiv.style.overflow = "clip";
    }
    return () => {
        topLevelDiv.style.padding = oldPadding;
        topLevelDiv.style.overflow = oldOverflow;
    };
};
