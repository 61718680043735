import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Badge, MenuIcon, Pane, Position, SideSheet, Tab, TabNavigation } from "evergreen-ui";
import { featureToggles } from "../../../common/utils";
import { isInternalEmail } from "../../../common/utils/isInternalEmail";
import { Logo } from "../common/Logo";
import { NavigationTab, tabs } from "./NavigationTabs";
import { UserContextLoadingState, useUserContext } from "../context/UserContext";
import { useMobileMediaQuery } from "../common/MediaQuery";
import { ColorPalette } from "../context/Theme";
import { useHeightChangeListener } from "../common/HeightChangeListener";
import { CLIENT_TYPE_ROUTES } from "./ClientTypeRoutes";

export const SideNavigation = () => {
    const history = useHistory();
    const location = useLocation();
    const isMobile = useMobileMediaQuery();
    const { user, logout, loadingState } = useUserContext();
    const [isSideSheetShowing, setSideSheetShowing] = useState<boolean>(false);
    const height = useHeightChangeListener();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [shouldRenderNav, setShouldRenderNav] = useState<boolean>(false);
    const [shouldRenderMobileNav, setShouldRenderMobileNav] = useState<boolean>(true);

    useEffect(() => {
        setLoaded(Boolean(user) && (loadingState === UserContextLoadingState.Loaded));
    }, [user, loadingState]);

    useEffect(() => {
        if (!isMobile) {
            // Hide after viewport width is resized to desktop.
            setSideSheetShowing(false);
        }
    }, [isMobile]);

    useEffect(() => {
        const fullScreenRegex = [
            "/start-creating",
            "/products/new",
            "/products/(.+)/catalog/edit",
        ].join("|");

        const fullScreenRegexMobile = [
            "/messages/(.+)"
        ].join("|");

        if (location.pathname.match(fullScreenRegex)) {
            setShouldRenderNav(false);
        } else if (location.pathname.match(fullScreenRegexMobile)) {
            setShouldRenderMobileNav(false);
            setShouldRenderNav(true);
        } else {
            setShouldRenderNav(true);
            setShouldRenderMobileNav(true);
        }

        const overrideMobileNavRegex = [
            "/new-user",
        ].join("|");
        if (location.pathname.match(overrideMobileNavRegex)) {
            setShouldRenderMobileNav(false);
        }

    }, [location]);

    const onSelectTab = (to: string) => {
        if (to.startsWith("http")) {
            window.open(to, "_blank");  // Open absolute URLs in a new browser tab.
        } else {
            history.push(to);
        }
        if (isMobile) {
            // Hide after clicking on a tab when on mobile.
            setSideSheetShowing(false);
        }
    };

    const shouldShowMessages = (tab: NavigationTab): boolean => {
        // Hack to exclude the Messages Tab if the user does not have any conversations.
        return tab.name !== "Messages" || Boolean(user?.twilioUserId);
    };


    const shouldShowTab = (tab: NavigationTab): boolean => {
        if (!user) return false;
        
        if (isInternalEmail(user.email)) {
            return shouldShowMessages(tab);
        }
        if (!user.clientTypes) return false;
    
        const allowedRoutes = user.clientTypes.flatMap(type => CLIENT_TYPE_ROUTES[type]);
        return shouldShowMessages(tab) && (!tab.to || allowedRoutes.includes(tab.to));
    };
    

    const renderTabs = (tabs: NavigationTab[]) => {
        return tabs.filter(shouldShowTab).map((tab: NavigationTab) => {
            const { name, to = "#", icon: TabIcon, showNewBadge } = tab;
            let isSelected = location.pathname.startsWith(to);
            const disableTab: boolean = featureToggles.isFeatureEnabled("newUserUX") &&
                (!user?.agreementAcceptedDate || !user?.phoneNumber) &&
                !isInternalEmail(user?.email || "") &&
                tab.to !== "/logout";
            return (
                <Tab
                    className="jc-nav-tab"
                    disabled={disableTab}
                    onSelect={() => onSelectTab(to)}
                    id={name}
                    key={name}
                    isSelected={isSelected}
                >
                    <TabIcon size={16} marginRight={10} />
                    {name}
                    {showNewBadge && <Badge marginLeft={8} color={"green"}>New</Badge>}
                </Tab>
            );
        });
    };

    const renderDesktopNavigation = () => {
        return (
            <Pane className={"jc-nav-container"} height={height}>
                <Logo className={"jc-nav-logo"} width={36} height={36} />
                <TabNavigation className={"jc-tab-navigation"}>
                    {renderTabs(tabs.top)}
                </TabNavigation>
                <TabNavigation className={"jc-tab-navigation bottom"}>
                    {renderTabs(tabs.bottom)}
                </TabNavigation>
            </Pane>
        );
    };

    const renderMobileNavigation = () => {
        if (!shouldRenderMobileNav) {
            return <></>;
        }

        return (
            <>
                <Pane className="jc-action-bar">
                    <Pane className="jc-action-bar-menu-container"
                        onClick={() => setSideSheetShowing(true)}
                    >
                        <MenuIcon
                            size={24}
                            color={ColorPalette.primaryText}
                        />
                    </Pane>
                    {!isSideSheetShowing ? (
                        <Logo width={28} height={28} />
                    ) : null}
                    <div className="jc-action-bar-right-spacer" />
                </Pane>
                <SideSheet
                    width="fit-content"
                    isShown={isSideSheetShowing}
                    onCloseComplete={() => setSideSheetShowing(false)}
                    position={Position.LEFT}
                    preventBodyScrolling
                >
                    {renderDesktopNavigation()}
                </SideSheet>
            </>
        );
    };

    const renderNavigation = () => {
        return isMobile ? renderMobileNavigation() : renderDesktopNavigation();
    };

    return loaded && shouldRenderNav ? renderNavigation() : null;
};
